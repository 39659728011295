import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAddressBook,
  faMailBulk,
  faPhone,
  faUser,
  faVoicemail,
} from "@fortawesome/free-solid-svg-icons"

const SecondPage = () => (
  <Layout>
    <SEO title="Contacto" />
    <div className="wrapper px-2 w-full my-20 lg:my-40">
      <h1 className="text-center text-lg font-semibold">
        Comencemos una conversación
      </h1>
      <div className="">
        <p>
          Estamos siempre atentos a tus solicitudes y valoramos tus preguntas.
          Una vez que te comuniques con nosotros por medio de este formulario o
          algún otro medio nos pondremos en contacto para resolver tus dudas a
          la brevedad.
        </p>
      </div>
    </div>
    <div
      className="wrapper px-2 w-full my-20 lg:my-40"
      data-sal="fade"
      data-sal-delay="0"
      data-sal-easing="ease"
      data-sal-duration="1000"
    >
      <form
        action="https://getform.io/f/ae80625b-c62d-4b4b-a5a8-7ed05ffd8ff2"
        method="POST"
        className="max-w-sm bg-gray-100 px-3 py-5 rounded shadow-lg my-10 m-auto"
      >
        <div className="flex flex-col space-y-3">
          <div
            className="flex items-center bg-white border border-gray-100 rounded px-2"
            data-sal="slide-left"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="700"
          >
            <FontAwesomeIcon
              color="gray"
              className="mr-2"
              size="lg"
              icon={faUser}
            />
            <input
              type="text"
              placeholder="Nombre"
              className="w-full py-2 px-1 placeholder-indigo-400 outline-none placeholder-opacity-50"
              name="nombre"
            />
          </div>
          <div
            className="flex items-center bg-white border border-gray-100 rounded px-2"
            data-sal="slide-right"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="700"
          >
            <FontAwesomeIcon
              color="gray"
              className="mr-2"
              size="lg"
              icon={faPhone}
            />

            <input
              type="text"
              placeholder="Telefono"
              className="w-full py-2 px-1 placeholder-indigo-400 outline-none placeholder-opacity-50"
              name="telefono"
            />
          </div>
          <div
            className="flex items-center bg-white border border-gray-100 rounded px-2"
            data-sal="slide-right"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="700"
          >
            <FontAwesomeIcon
              color="gray"
              className="mr-2"
              size="lg"
              icon={faMailBulk}
            />

            <input
              type="text"
              placeholder="Email"
              className="w-full py-2 px-1 placeholder-indigo-400 outline-none placeholder-opacity-50"
              name="email"
            />
          </div>
          <div
            className="flex items-center bg-white border border-gray-100 rounded px-2"
            data-sal="slide-left"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="700"
          >
            <FontAwesomeIcon
              color="gray"
              className="mr-2"
              size="lg"
              icon={faAddressBook}
            />

            <textarea
              placeholder="Mensaje"
              className="w-full resize-none py-2 px-1 placeholder-indigo-400 outline-none placeholder-opacity-50"
              name="mensaje"
            ></textarea>
          </div>
          <button
            type="submit"
            className="text-white  bg-indigo-500 hover:bg-indigo-700 px-4 py-2 rounded"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  </Layout>
)

export default SecondPage
